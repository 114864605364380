import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

import tabletImg from "../images/index-1-415x592.png"

const Services = () => {
  const { t } = useTranslation()

  return (
    <>
      <section
        className="section section-sm section-first bg-default text-center"
        id="services"
      >
        <div className="container">
          <div className="row row-30 justify-content-center">
            <div className="col-md-7 col-lg-5 col-xl-6 text-lg-left wow fadeInUp">
              <img src={tabletImg} alt="" width="415" height="592" />
            </div>
            <div className="col-lg-7 col-xl-6">
              <div className="row row-30">
                <div className="col-sm-6 wow fadeInRight">
                  <article className="box-icon-modern box-icon-modern-custom">
                    <div>
                      <h3 className="box-icon-modern-big-title">
                        {t("what_we_offer")}
                      </h3>
                      <div className="box-icon-modern-decor"></div>
                      <a
                        className="button button-primary button-ujarak"
                        href="#projects"
                      >
                        {t("view_all_services")}
                      </a>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 wow fadeInRight" data-wow-delay=".1s">
                  <article className="box-icon-modern box-icon-modern-2">
                    <div className="box-icon-modern-icon linearicons-phone-in-out"></div>
                    <h5 className="box-icon-modern-title">
                      <a href="#projects">
                        {t("corporate")}
                        <br />
                        {t("solutions")}
                      </a>
                    </h5>
                    <div className="box-icon-modern-decor"></div>
                    <p className="box-icon-modern-text">
                      {t("corporate_solutions_description")}
                    </p>
                  </article>
                </div>
                <div className="col-sm-6 wow fadeInRight" data-wow-delay=".2s">
                  <article className="box-icon-modern box-icon-modern-2">
                    <div className="box-icon-modern-icon linearicons-headset"></div>
                    <h5 className="box-icon-modern-title">
                      <a href="#projects">
                        {t("mobile_development")}
                        <br />
                        {t("b2b_b2c")}
                      </a>
                    </h5>
                    <div className="box-icon-modern-decor"></div>
                    <p className="box-icon-modern-text">
                      {t("mobile_development_description")}
                    </p>
                  </article>
                </div>
                <div className="col-sm-6 wow fadeInRight" data-wow-delay=".3s">
                  <article className="box-icon-modern box-icon-modern-2">
                    <div className="box-icon-modern-icon linearicons-outbox"></div>
                    <h5 className="box-icon-modern-title">
                      <a href="#projects">
                        {t("cloud_based")}
                        <br />
                        {t("solutions")}
                      </a>
                    </h5>
                    <div className="box-icon-modern-decor"></div>
                    <p className="box-icon-modern-text">
                      {t("cloud_based_development_description")}
                    </p>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Services
