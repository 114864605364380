import React from "react"

import onir from "../images/onir/3-6inch.jpg"
import maxiwoo from "../images/maxiwoo/maxiwoo.png"
import maxiwooadmin from "../images/maxiwoo/admin.png"
import screenist from "../images/screenist/screenist.png"
import preview20m from "../images/20m/20m.png"
import apollo from "../images/apolloambulance/apolloambulance.png"
import stemxpreview from "../images/stemx/preview.png"
import eonLogo from "../images/eon/EON_logo.png"
import eventus from "../images/eon/eventus.png"
import waberers from "../images/waberers/waberers_logo.jpg"
import iasidbet from "../images/isaidbet/login.png"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

const LatestProject = () => {
  const { t } = useTranslation()

  return (
    // <!-- Latest Projects-->
    <section
      className="section section-sm section-fluid bg-default text-center"
      id="projects"
    >
      <div className="container-fluid">
        <h2 className="wow fadeInLeft">{t("latest_projects")}</h2>
        <p className="quote-jean wow fadeInRight" data-wow-delay=".1s">
          {t("portfolio_description")}
        </p>
        <div className="isotope-filters isotope-filters-horizontal">
          <button
            className="isotope-filters-toggle button button-md button-icon button-icon-right button-default-outline button-wapasha"
            data-custom-toggle="#isotope-3"
            data-custom-toggle-hide-on-blur="true"
          >
            <span className="icon fa fa-caret-down"></span>
            {t("filter")}
          </button>
          <ul className="isotope-filters-list" id="isotope-3">
            <li>
              <a
                className="active"
                href="#"
                data-isotope-filter="*"
                data-isotope-group="gallery"
              >
                {t("all_filter")}
              </a>
            </li>
            <li>
              <a
                href="#"
                data-isotope-filter="mobile"
                data-isotope-group="gallery"
              >
                {t("mobile_filter")}
              </a>
            </li>
            <li>
              <a
                href="#"
                data-isotope-filter="web"
                data-isotope-group="gallery"
              >
                {t("web_filter")}
              </a>
            </li>
            <li>
              <a
                href="#"
                data-isotope-filter="complex"
                data-isotope-group="gallery"
              >
                {t("complex_filter")}
              </a>
            </li>
          </ul>
        </div>
        <div
          className="row row-30 isotope"
          data-isotope-layout="fitRows"
          data-isotope-group="gallery"
          data-lightgallery="group"
        >
          {/* IBS */}

          <div
            className="col-sm-6 col-lg-4 col-xxl-3 isotope-item wow fadeInRight"
            data-filter="mobile"
            data-wow-delay=".1s"
          >
            {/* <Link to="/details/maxi-woo-details"> */}
            <article className="thumbnail thumbnail-classic thumbnail-md">
              <div className="thumbnail-classic-figure">
                <img src={maxiwoo} alt="" width="420" height="350" />
              </div>
              <div className="thumbnail-classic-caption">
                <div className="thumbnail-classic-title-wrap">
                  <a
                    className="icon fl-bigmug-line-zoom60"
                    href={maxiwoo}
                    data-lightgallery="item"
                  >
                    <img src={maxiwoo} alt="" width="420" height="350" />
                  </a>
                  <h5 className="thumbnail-classic-title">
                    {t("maxi_woo_title")}
                  </h5>
                </div>
                <p className="thumbnail-classic-text">
                  {t("maxi_woo_header_text")}
                </p>
              </div>
            </article>
            {/* </Link> */}
          </div>

          {/* ScreenIst */}
          <div
            className="col-sm-6 col-lg-4 col-xxl-3 isotope-item wow fadeInRight"
            data-filter="mobile"
            data-wow-delay=".1s"
          >
            {/* <!-- Thumbnail Classic--> */}
            {/* <Link to="/details/screen-ist-details"> */}
            <article className="thumbnail thumbnail-classic thumbnail-md">
              <div className="thumbnail-classic-figure">
                <img src={screenist} alt="" width="420" height="350" />
              </div>
              <div className="thumbnail-classic-caption">
                <div className="thumbnail-classic-title-wrap">
                  <a
                    className="icon fl-bigmug-line-zoom60"
                    href={screenist}
                    data-lightgallery="item"
                  >
                    <img src={screenist} alt="" width="420" height="350" />
                  </a>
                  <h5 className="thumbnail-classic-title">
                    {t("screenist_title")}
                  </h5>
                </div>
                <p className="thumbnail-classic-text">
                  {t("screenist_header_text")}
                </p>
              </div>
            </article>
            {/* </Link> */}
          </div>

          {/* ONIR */}
          <div
            className="col-sm-6 col-lg-4 col-xxl-3 isotope-item wow fadeInRight"
            data-filter="mobile"
            data-wow-delay=".1s"
          >
            {/* <Link to="/details/onir-details"> */}
            <article className="thumbnail thumbnail-classic thumbnail-md">
              <div className="thumbnail-classic-figure">
                <img src={onir} alt="" width="420" height="350" />
              </div>
              <div className="thumbnail-classic-caption">
                <div className="thumbnail-classic-title-wrap">
                  <a
                    className="icon fl-bigmug-line-zoom60"
                    href={onir}
                    data-lightgallery="item"
                  >
                    <img src={onir} alt="" width="420" height="350" />
                  </a>
                  <h5 className="thumbnail-classic-title">{t("onir_title")}</h5>
                </div>
                <p className="thumbnail-classic-text">
                  {t("onir_header_text")}
                </p>
              </div>
            </article>
            {/* </Link> */}
          </div>
          {/* 20M */}
          <div
            className="col-sm-6 col-lg-4 col-xxl-3 isotope-item wow fadeInRight"
            data-filter="mobile"
            data-wow-delay=".1s"
          >
            {/* <Link to="/details/20m-details"> */}
            <article className="thumbnail thumbnail-classic thumbnail-md">
              <div className="thumbnail-classic-figure">
                <img src={preview20m} alt="" width="420" height="350" />
              </div>
              <div className="thumbnail-classic-caption">
                <div className="thumbnail-classic-title-wrap">
                  <a
                    className="icon fl-bigmug-line-zoom60"
                    href="https://youtu.be/tuJz8kziRBM"
                    data-lightgallery="item"
                  >
                    <img src={preview20m} alt="" width="420" height="350" />
                  </a>
                  <h5 className="thumbnail-classic-title">
                    {t("twenty_m_title")}
                  </h5>
                </div>
                <p className="thumbnail-classic-text">
                  {t("twenty_m_header_text")}
                </p>
              </div>
            </article>
            {/* </Link> */}
          </div>
          {/* IBS Admin */}
          <div
            className="col-sm-6 col-lg-4 col-xxl-3 isotope-item wow fadeInRight"
            data-filter="web"
            data-wow-delay=".1s"
          >
            {/* <!-- Thumbnail Classic--> */}
            {/* <Link to="/details/maxi-woo-admin-details"> */}
            <article className="thumbnail thumbnail-classic thumbnail-md">
              <div className="thumbnail-classic-figure">
                <img src={maxiwooadmin} alt="" width="420" height="350" />
              </div>
              <div className="thumbnail-classic-caption">
                <div className="thumbnail-classic-title-wrap">
                  <a
                    className="icon fl-bigmug-line-zoom60"
                    href={maxiwooadmin}
                    data-lightgallery="item"
                  >
                    <img src={maxiwooadmin} alt="" width="420" height="350" />
                  </a>
                  <h5 className="thumbnail-classic-title">
                    {t("max_woo_admin_title")}
                  </h5>
                </div>
                <p className="thumbnail-classic-text">
                  {t("max_woo_admin_header_text")}
                </p>
              </div>
            </article>
            {/* </Link> */}
          </div>
          {/* ISaidBet */}
          <div
            className="col-sm-6 col-lg-4 col-xxl-3 isotope-item wow fadeInRight"
            data-filter="web"
            data-wow-delay=".1s"
          >
            {/* <!-- Thumbnail Classic--> */}
            {/* <Link to="/details/i-said-bet-details"> */}
            <article className="thumbnail thumbnail-classic thumbnail-md">
              <div className="thumbnail-classic-figure">
                <img src={iasidbet} alt="" width="420" height="350" />
              </div>
              <div className="thumbnail-classic-caption">
                <div className="thumbnail-classic-title-wrap">
                  <a
                    className="icon fl-bigmug-line-zoom60"
                    href={iasidbet}
                    data-lightgallery="item"
                  >
                    <img src={iasidbet} alt="" width="420" height="350" />
                  </a>
                  <h5 className="thumbnail-classic-title">
                    {t("i_said_bet_title")}
                  </h5>
                </div>
                <p className="thumbnail-classic-text">
                  {t("i_said_bet_header_text")}
                </p>
              </div>
            </article>
            {/* </Link> */}
          </div>
          {/* ApolloAmbulance */}
          <div
            className="col-sm-6 col-lg-4 col-xxl-3 isotope-item wow fadeInRight"
            data-filter="web"
            data-wow-delay=".1s"
          >
            {/* <!-- Thumbnail Classic--> */}
            {/* <Link to="/details/apollo-ambulance-details"> */}
            <article className="thumbnail thumbnail-classic thumbnail-md">
              <div className="thumbnail-classic-figure">
                <img src={apollo} alt="" width="420" height="350" />
              </div>
              <div className="thumbnail-classic-caption">
                <div className="thumbnail-classic-title-wrap">
                  <a
                    className="icon fl-bigmug-line-zoom60"
                    href={apollo}
                    data-lightgallery="item"
                  >
                    <img src={apollo} alt="" width="420" height="350" />
                  </a>
                  <h5 className="thumbnail-classic-title">
                    {t("apollo_ambulance_title")}
                  </h5>
                </div>
                <p className="thumbnail-classic-text">
                  {t("apollo_ambulance_header_text")}
                </p>
              </div>
            </article>
            {/* </Link> */}
          </div>
          {/* IT */}
          <div
            className="col-sm-6 col-lg-4 col-xxl-3 isotope-item wow fadeInRight"
            data-filter="complex"
            data-wow-delay=".1s"
          >
            {/* <!-- Thumbnail Classic--> */}
            {/* <Link to="/details/i-said-bet-details"> */}
            <article className="thumbnail thumbnail-classic thumbnail-md">
              <div className="thumbnail-classic-figure">
                <img src={stemxpreview} alt="" width="420" height="350" />
              </div>
              <div className="thumbnail-classic-caption">
                <div className="thumbnail-classic-title-wrap">
                  <a
                    className="icon fl-bigmug-line-zoom60"
                    href="https://youtu.be/18Z8p-tirmo"
                    data-lightgallery="item"
                  >
                    <img src={stemxpreview} alt="" width="420" height="350" />
                  </a>
                  <h5 className="thumbnail-classic-title">
                    {t("ticketing_system_title")}
                  </h5>
                </div>
                <p className="thumbnail-classic-text">
                  {t("ticketing_system_header_text")}
                </p>
              </div>
            </article>
            {/* </Link> */}
          </div>
          {/* Waberers */}
          <div
            className="col-sm-6 col-lg-4 col-xxl-3 isotope-item wow fadeInRight"
            data-filter="complex"
            data-wow-delay=".1s"
          >
            {/* <!-- Thumbnail Classic--> */}
            {/* <Link to="/details/waberers-details"> */}
            <article className="thumbnail thumbnail-classic thumbnail-md">
              <div className="thumbnail-classic-figure">
                <img src={waberers} alt="" width="420" height="350" />
              </div>
              <div className="thumbnail-classic-caption">
                <div className="thumbnail-classic-title-wrap">
                  <a
                    className="icon fl-bigmug-line-zoom60"
                    href={waberers}
                    data-lightgallery="item"
                  >
                    <img src={waberers} alt="" width="420" height="350" />
                  </a>
                  <h5 className="thumbnail-classic-title">
                    {t("waberers_title")}
                  </h5>
                </div>
                <p className="thumbnail-classic-text">
                  {t("waberers_header_text")}
                </p>
              </div>
            </article>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default LatestProject
