import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Services from "../components/services"
import Cta from "../components/cta"
import LatestProject from "../components/latest-project"
import Experience from "../components/experience"
import Contact from "../components/contact"

const IndexPage = () => {
  return (
    <Layout location="/">
      <SEO />
      <Services />
      <Cta />
      <LatestProject />
      <Experience />
      {/* <Team /> */}
      {/* <PeopleSays /> */}
      <Contact />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
