import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

import Helmet from "react-helmet"

const Contact = () => {
  const { t } = useTranslation()

  return (
    // <!-- Contact information-->
    <section className="section section-sm bg-default" id="contacts">
      <div className="container">
        <div className="row row-30 justify-content-center">
          <div className="col-sm-8 col-md-6 col-lg-4">
            <article className="box-contacts">
              <div className="box-contacts-body">
                <div className="box-contacts-icon fl-bigmug-line-chat55"></div>
                <div className="box-contacts-decor"></div>
                <p className="box-contacts-link">
                  <a
                    href="mailto:info@mxcsoftware.it"
                    onClick={() =>
                      window.gtag("event", "mail_clicked", {
                        source: "info@mxcsoftware.it",
                        component: "contact",
                      })
                    }
                  >
                    info@mxcsoftware.it
                  </a>
                </p>
                <p className="box-contacts-link">
                  <a
                    href="mailto:info@mxcsoftware.hu"
                    onClick={() =>
                      window.gtag("event", "mail_clicked", {
                        source: "info@mxcsoftware.hu",
                        component: "contact",
                      })
                    }
                  >
                    info@mxcsoftware.hu
                  </a>
                </p>
              </div>
            </article>
          </div>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <article className="box-contacts">
              <div className="box-contacts-body">
                <div className="box-contacts-icon fl-bigmug-line-cellphone55"></div>
                <div className="box-contacts-decor"></div>
                <p className="box-contacts-link">
                  <a href="tel:#">+36 30 543 7137</a>
                </p>
                <p className="box-contacts-link">
                  <a href="tel:#">+36 30 720 1159</a>
                </p>
              </div>
            </article>
          </div>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <article className="box-contacts">
              <div className="box-contacts-body">
                <div className="box-contacts-icon fl-bigmug-line-share27"></div>
                <div className="box-contacts-decor"></div>
                <ul className="">
                  <li className="rd-navbar-share-list-item">
                    <a
                      // className="icon fa fa-facebook"
                      target="_blank"
                      href="https://www.facebook.com/mxcsoftware"
                      onClick={() =>
                        window.gtag("event", "facebook_clicked", {
                          source: "share_icon",
                          component: "contact",
                        })
                      }
                    >
                      Facebook
                    </a>
                  </li>
                  {/* <li className="rd-navbar-share-list-item">
                      <a className="icon fa fa-twitter" href="#"></a>
                    </li> */}
                  <li className="rd-navbar-share-list-item">
                    <a
                      target="_blank"
                      // className="icon fa fa-linkedin"
                      href="https://www.linkedin.com/company/mxcsoftware"
                      onClick={() =>
                        window.gtag("event", "linkedin_clicked", {
                          source: "share_icon",
                          component: "contact",
                        })
                      }
                    >
                      LinkedIn
                    </a>
                  </li>
                  {/* <li className="rd-navbar-share-list-item">
                      <a className="icon fa fa-instagram" href="#"></a>
                    </li> */}
                </ul>
              </div>
            </article>
          </div>
          {/* <div className="col-sm-8 col-md-6 col-lg-4">
          <article className="box-contacts">
            <div className="box-contacts-body">
              <div className="box-contacts-icon fl-bigmug-line-up104"></div>
              <div className="box-contacts-decor"></div>
              <p className="box-contacts-link">
                <a href="#">{t("contact_location_1")}</a>
              </p>
              <p className="box-contacts-link">
                <a href="#">{t("contact_location_2")}</a>
              </p>
            </div>
          </article>
        </div> */}
        </div>
      </div>
    </section>
  )
}

export default Contact
