import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

const Experience = () => {
  const { t } = useTranslation()

  return (
    // <!-- Years of experience-->
    <section className="section section-sm bg-default">
      <div className="container">
        <div className="row row-30 row-xl-24 justify-content-center align-items-center align-items-lg-start text-left">
          <div className="col-md-6 col-lg-5 col-xl-4 text-center">
            <a className="text-img" href="#">
              <div id="particles-js"></div>
              <span className="counter">{t("experience_years")}</span>
            </a>
          </div>
          <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4">
            <div className="text-width-extra-small offset-top-lg-24 wow fadeInUp">
              <h3 className="title-decoration-lines-left">
                {t("experience_year_experience")}
              </h3>
              <p className="text-gray-500">{t("experience_text")}</p>
            </div>
          </div>
          <div
            className="col-sm-10 col-md-8 col-lg-6 col-xl-4 wow fadeInRight"
            data-wow-delay=".1s"
          >
            <div className="row justify-content-center border-2-column offset-top-xl-26">
              <div className="col-9 col-sm-6">
                <div className="counter-amy">
                  <div className="counter-amy-number">
                    <span className="symbol">
                      {t("experience_app_counter_number")}
                    </span>
                  </div>
                  <h6 className="counter-amy-title">
                    {t("experience_app_counter_text")}
                  </h6>
                </div>
              </div>
              <div className="col-9 col-sm-6">
                <div className="counter-amy">
                  <div className="counter-amy-number">
                    <span className="counter">
                      {t("experience_guidance_counter_number")}
                    </span>
                  </div>
                  <h6 className="counter-amy-title">
                    {t("experience_guidance_title")}
                  </h6>
                </div>
              </div>
              <div className="col-9 col-sm-6">
                <div className="counter-amy">
                  <div className="counter-amy-number">
                    <span className="counter">
                      {t("experience_award_winning_number")}
                    </span>
                  </div>
                  <h6 className="counter-amy-title">
                    {t("experience_award_winning_text")}
                  </h6>
                </div>
              </div>
              {/* <div className="col-9 col-sm-6">
              <div className="counter-amy">
                <div className="counter-amy-number">
                  <span className="counter">
                    {t("experience_employee_number")}
                  </span>
                </div>
                <h6 className="counter-amy-title">
                  {t("experience_employee_title")}
                </h6>
              </div>
            </div> */}
            </div>
          </div>
          {/* <div className="col-lg-6 col-xl-12 align-self-center">
          <div className="row row-30 justify-content-center">
            <div className="col-sm-6 col-md-5 col-lg-6 col-xl-3 wow fadeInLeft">
              <a className="clients-classic" href="#">
                <img src={eonLogo} alt="" width="270" height="117" />
              </a>
            </div>
            <div
              className="col-sm-6 col-md-5 col-lg-6 col-xl-3 wow fadeInLeft"
              data-wow-delay=".1s"
            >
              <a className="clients-classic" href="#">
                <img
                  src="images/clients-10-270x117.png"
                  alt=""
                  width="270"
                  height="117"
                />
              </a>
            </div>
            <div
              className="col-sm-6 col-md-5 col-lg-6 col-xl-3 wow fadeInLeft"
              data-wow-delay=".2s"
            >
              <a className="clients-classic" href="#">
                <img
                  src="images/clients-3-270x117.png"
                  alt=""
                  width="270"
                  height="117"
                />
              </a>
            </div>
            <div
              className="col-sm-6 col-md-5 col-lg-6 col-xl-3 wow fadeInLeft"
              data-wow-delay=".3s"
            >
              <a className="clients-classic" href="#">
                <img
                  src="images/clients-11-270x117.png"
                  alt=""
                  width="270"
                  height="117"
                />
              </a>
            </div>
          </div>
        </div> */}
        </div>
      </div>
    </section>
  )
}

export default Experience
