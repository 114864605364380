import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

import paralaxBg from "../images/parallax-1.jpg"

const Cta = () => {
  const { t } = useTranslation()

  return (
    <section id="calculator" className="section section-fluid bg-default">
      <div className="parallax-container" data-parallax-img={paralaxBg}>
        <div className="parallax-content section-sm context-dark">
          <div className="container">
            <div className="row row-30 justify-content-end text-right">
              <div className="col-sm-7">
                <h3 className="wow fadeInLeft">{t("let_s_develop")}</h3>
                <p>{t("let_s_develop_description")}</p>
                <div className="wow fadeInLeft">
                  <iframe
                    src="https://links.collect.chat/5fd9145e278c444f79e6a183"
                    height="500"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Cta
